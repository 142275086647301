import { CanActivateFn, Router } from '@angular/router';
import { UserControllerService } from "../services/user-controller.service";
import { inject } from "@angular/core";
import { SafeCryptoService } from "../../api/services/safe-crypto.service";
import { catchError, map, of } from "rxjs";

export const caseGuard : CanActivateFn = (route, state) => {
  const userControllerService: UserControllerService = inject(UserControllerService);
  const safeCryptoService: SafeCryptoService = inject(SafeCryptoService);
  const router = inject(Router);
  const { user } = userControllerService;
  const userEntity = user();

  const { actualIncidentId, termsAccepted } = userEntity;

  /** Если существует активный инцидент, разрешаем переход */
  if (actualIncidentId) {
    return true;
  }

  /** Если нет actualIncidentId и termsAccepted = true то нужно создать новый инцидент и разрешаем переход */
  if (!actualIncidentId && termsAccepted) {
    return safeCryptoService.postCreateIncident(userEntity.id).pipe(
      map(({ id }): boolean => Boolean(id)),
      catchError(() => {
        return of(router.createUrlTree(['/']));
      })
    );
  }

  return router.createUrlTree(['/']);
};
