import { AnimationBuilder } from '@angular/animations';
import { Component, ElementRef, ViewChild } from '@angular/core';
import * as lottie from 'lottie-web';

const AnimationData = {
  "v": "5.12.2",
  "fr": 30,
  "ip": 0,
  "op": 150,
  "w": 256,
  "h": 256,
  "ddd": 0,
  "assets": [{
    "id": "comp_0",
    "fr": 30,
    "layers": [{
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.83],
              "y": [0.83]
            },
            "o": {
              "x": [0.17],
              "y": [0.17]
            },
            "t": 0,
            "s": [0]
          }, {
            "t": 150,
            "s": [-360]
          }]
        },
        "p": {
          "a": 0,
          "k": [209.67, 200.15, 0],
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "o": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "v": [
                [1.57, -1.57],
                [8.72, 0],
                [1.57, 1.57],
                [0, 8.72],
                [-1.57, 1.57],
                [-8.72, 0],
                [-1.57, -1.57],
                [0, -8.72]
              ],
              "c": true
            }
          }
        }, {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1, 1, 1, 1]
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "r": 1,
          "bm": 0
        }, {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [0, 0]
          },
          "a": {
            "a": 0,
            "k": [0, 0]
          },
          "s": {
            "a": 0,
            "k": [100, 100]
          },
          "r": {
            "a": 0,
            "k": 0
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "sk": {
            "a": 0,
            "k": 0
          },
          "sa": {
            "a": 0,
            "k": 0
          }
        }],
        "bm": 0
      }],
      "ip": 0,
      "op": 750,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.83],
              "y": [0.83]
            },
            "o": {
              "x": [0.17],
              "y": [0.17]
            },
            "t": 0,
            "s": [0]
          }, {
            "t": 150,
            "s": [-360]
          }]
        },
        "p": {
          "a": 0,
          "k": [49.05, 197.83, 0],
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "o": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "v": [
                [0.79, -0.79],
                [4.36, 0],
                [0.79, 0.79],
                [0, 4.36],
                [-0.79, 0.79],
                [-4.36, 0],
                [-0.79, -0.79],
                [0, -4.36]
              ],
              "c": true
            }
          }
        }, {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1, 1, 1, 1]
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "r": 1,
          "bm": 0
        }, {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [0, 0]
          },
          "a": {
            "a": 0,
            "k": [0, 0]
          },
          "s": {
            "a": 0,
            "k": [100, 100]
          },
          "r": {
            "a": 0,
            "k": 0
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "sk": {
            "a": 0,
            "k": 0
          },
          "sa": {
            "a": 0,
            "k": 0
          }
        }],
        "bm": 0
      }],
      "ip": 0,
      "op": 750,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.83],
              "y": [0.83]
            },
            "o": {
              "x": [0.17],
              "y": [0.17]
            },
            "t": 0,
            "s": [0]
          }, {
            "t": 150,
            "s": [-360]
          }]
        },
        "p": {
          "a": 0,
          "k": [46.63, 83.7, 0],
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "o": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "v": [
                [1.57, -1.57],
                [8.72, 0],
                [1.57, 1.57],
                [0, 8.72],
                [-1.57, 1.57],
                [-8.72, 0],
                [-1.57, -1.57],
                [0, -8.72]
              ],
              "c": true
            }
          }
        }, {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1, 1, 1, 1]
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "r": 1,
          "bm": 0
        }, {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [0, 0]
          },
          "a": {
            "a": 0,
            "k": [0, 0]
          },
          "s": {
            "a": 0,
            "k": [100, 100]
          },
          "r": {
            "a": 0,
            "k": 0
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "sk": {
            "a": 0,
            "k": 0
          },
          "sa": {
            "a": 0,
            "k": 0
          }
        }],
        "bm": 0
      }],
      "ip": 0,
      "op": 750,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.83],
              "y": [0.83]
            },
            "o": {
              "x": [0.17],
              "y": [0.17]
            },
            "t": 0,
            "s": [0]
          }, {
            "t": 150,
            "s": [-360]
          }]
        },
        "p": {
          "a": 0,
          "k": [159.98, 204.55, 0],
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "o": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "v": [
                [-2.41, -2.41],
                [2.41, -2.41],
                [2.41, 2.41],
                [-2.41, 2.41]
              ],
              "c": true
            }
          }
        }, {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1, 1, 1, 1]
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "r": 1,
          "bm": 0
        }, {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [0, 0]
          },
          "a": {
            "a": 0,
            "k": [0, 0]
          },
          "s": {
            "a": 0,
            "k": [100, 100]
          },
          "r": {
            "a": 0,
            "k": 45
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "sk": {
            "a": 0,
            "k": 0
          },
          "sa": {
            "a": 0,
            "k": 0
          }
        }],
        "bm": 0
      }],
      "ip": 0,
      "op": 750,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 5,
      "ty": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.83],
              "y": [0.83]
            },
            "o": {
              "x": [0.17],
              "y": [0.17]
            },
            "t": 0,
            "s": [0]
          }, {
            "t": 150,
            "s": [-360]
          }]
        },
        "p": {
          "a": 0,
          "k": [108.69, 124.59, 0],
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "o": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "v": [
                [-2.15, -2.15],
                [2.15, -2.15],
                [2.15, 2.15],
                [-2.15, 2.15]
              ],
              "c": true
            }
          }
        }, {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1, 1, 1, 1]
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "r": 1,
          "bm": 0
        }, {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [0, 0]
          },
          "a": {
            "a": 0,
            "k": [0, 0]
          },
          "s": {
            "a": 0,
            "k": [100, 100]
          },
          "r": {
            "a": 0,
            "k": 45
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "sk": {
            "a": 0,
            "k": 0
          },
          "sa": {
            "a": 0,
            "k": 0
          }
        }],
        "bm": 0
      }],
      "ip": 0,
      "op": 750,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 6,
      "ty": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.83],
              "y": [0.83]
            },
            "o": {
              "x": [0.17],
              "y": [0.17]
            },
            "t": 0,
            "s": [0]
          }, {
            "t": 150,
            "s": [-360]
          }]
        },
        "p": {
          "a": 0,
          "k": [118, 247.25, 0],
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "o": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "v": [
                [-1.66, -1.66],
                [1.66, -1.66],
                [1.66, 1.66],
                [-1.66, 1.66]
              ],
              "c": true
            }
          }
        }, {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1, 1, 1, 1]
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "r": 1,
          "bm": 0
        }, {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [0, 0]
          },
          "a": {
            "a": 0,
            "k": [0, 0]
          },
          "s": {
            "a": 0,
            "k": [100, 100]
          },
          "r": {
            "a": 0,
            "k": 45
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "sk": {
            "a": 0,
            "k": 0
          },
          "sa": {
            "a": 0,
            "k": 0
          }
        }],
        "bm": 0
      }],
      "ip": 0,
      "op": 750,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 7,
      "ty": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.83],
              "y": [0.83]
            },
            "o": {
              "x": [0.17],
              "y": [0.17]
            },
            "t": 0,
            "s": [0]
          }, {
            "t": 150,
            "s": [-360]
          }]
        },
        "p": {
          "a": 0,
          "k": [250.92, 125.8, 0],
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "o": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "v": [
                [-0.85, -0.85],
                [0.85, -0.85],
                [0.85, 0.85],
                [-0.85, 0.85]
              ],
              "c": true
            }
          }
        }, {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1, 1, 1, 1]
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "r": 1,
          "bm": 0
        }, {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [0, 0]
          },
          "a": {
            "a": 0,
            "k": [0, 0]
          },
          "s": {
            "a": 0,
            "k": [100, 100]
          },
          "r": {
            "a": 0,
            "k": 45
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "sk": {
            "a": 0,
            "k": 0
          },
          "sa": {
            "a": 0,
            "k": 0
          }
        }],
        "bm": 0
      }],
      "ip": 0,
      "op": 750,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 8,
      "ty": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.83],
              "y": [0.83]
            },
            "o": {
              "x": [0.17],
              "y": [0.17]
            },
            "t": 0,
            "s": [0]
          }, {
            "t": 150,
            "s": [-360]
          }]
        },
        "p": {
          "a": 0,
          "k": [200.95, 57.62, 0],
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "o": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "v": [
                [-0.85, -0.85],
                [0.85, -0.85],
                [0.85, 0.85],
                [-0.85, 0.85]
              ],
              "c": true
            }
          }
        }, {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1, 1, 1, 1]
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "r": 1,
          "bm": 0
        }, {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [0, 0]
          },
          "a": {
            "a": 0,
            "k": [0, 0]
          },
          "s": {
            "a": 0,
            "k": [100, 100]
          },
          "r": {
            "a": 0,
            "k": 45
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "sk": {
            "a": 0,
            "k": 0
          },
          "sa": {
            "a": 0,
            "k": 0
          }
        }],
        "bm": 0
      }],
      "ip": 0,
      "op": 750,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 9,
      "ty": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.83],
              "y": [0.83]
            },
            "o": {
              "x": [0.17],
              "y": [0.17]
            },
            "t": 0,
            "s": [0]
          }, {
            "t": 150,
            "s": [-360]
          }]
        },
        "p": {
          "a": 0,
          "k": [4.21, 115.54, 0],
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "o": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "v": [
                [-0.85, -0.85],
                [0.85, -0.85],
                [0.85, 0.85],
                [-0.85, 0.85]
              ],
              "c": true
            }
          }
        }, {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1, 1, 1, 1]
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "r": 1,
          "bm": 0
        }, {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [0, 0]
          },
          "a": {
            "a": 0,
            "k": [0, 0]
          },
          "s": {
            "a": 0,
            "k": [100, 100]
          },
          "r": {
            "a": 0,
            "k": 45
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "sk": {
            "a": 0,
            "k": 0
          },
          "sa": {
            "a": 0,
            "k": 0
          }
        }],
        "bm": 0
      }],
      "ip": 0,
      "op": 750,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 10,
      "ty": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.83],
              "y": [0.83]
            },
            "o": {
              "x": [0.17],
              "y": [0.17]
            },
            "t": 0,
            "s": [0]
          }, {
            "t": 150,
            "s": [-360]
          }]
        },
        "p": {
          "a": 0,
          "k": [37.31, 148.82, 0],
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "o": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "v": [
                [-1.66, -1.66],
                [1.66, -1.66],
                [1.66, 1.66],
                [-1.66, 1.66]
              ],
              "c": true
            }
          }
        }, {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1, 1, 1, 1]
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "r": 1,
          "bm": 0
        }, {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [0, 0]
          },
          "a": {
            "a": 0,
            "k": [0, 0]
          },
          "s": {
            "a": 0,
            "k": [100, 100]
          },
          "r": {
            "a": 0,
            "k": 45
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "sk": {
            "a": 0,
            "k": 0
          },
          "sa": {
            "a": 0,
            "k": 0
          }
        }],
        "bm": 0
      }],
      "ip": 0,
      "op": 750,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 11,
      "ty": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.83],
              "y": [0.83]
            },
            "o": {
              "x": [0.17],
              "y": [0.17]
            },
            "t": 0,
            "s": [0]
          }, {
            "t": 150,
            "s": [-360]
          }]
        },
        "p": {
          "a": 0,
          "k": [122.7, 10.08, 0],
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "o": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "v": [
                [-1.66, -1.66],
                [1.66, -1.66],
                [1.66, 1.66],
                [-1.66, 1.66]
              ],
              "c": true
            }
          }
        }, {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1, 1, 1, 1]
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "r": 1,
          "bm": 0
        }, {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [0, 0]
          },
          "a": {
            "a": 0,
            "k": [0, 0]
          },
          "s": {
            "a": 0,
            "k": [100, 100]
          },
          "r": {
            "a": 0,
            "k": 45
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "sk": {
            "a": 0,
            "k": 0
          },
          "sa": {
            "a": 0,
            "k": 0
          }
        }],
        "bm": 0
      }],
      "ip": 0,
      "op": 750,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 12,
      "ty": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.83],
              "y": [0.83]
            },
            "o": {
              "x": [0.17],
              "y": [0.17]
            },
            "t": 0,
            "s": [0]
          }, {
            "t": 150,
            "s": [-360]
          }]
        },
        "p": {
          "a": 0,
          "k": [189.14, 58.9, 0],
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "o": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "v": [
                [-1.66, -1.66],
                [1.66, -1.66],
                [1.66, 1.66],
                [-1.66, 1.66]
              ],
              "c": true
            }
          }
        }, {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1, 1, 1, 1]
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "r": 1,
          "bm": 0
        }, {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [0, 0]
          },
          "a": {
            "a": 0,
            "k": [0, 0]
          },
          "s": {
            "a": 0,
            "k": [100, 100]
          },
          "r": {
            "a": 0,
            "k": 45
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "sk": {
            "a": 0,
            "k": 0
          },
          "sa": {
            "a": 0,
            "k": 0
          }
        }],
        "bm": 0
      }],
      "ip": 0,
      "op": 750,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 13,
      "ty": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.83],
              "y": [0.83]
            },
            "o": {
              "x": [0.17],
              "y": [0.17]
            },
            "t": 0,
            "s": [0]
          }, {
            "t": 150,
            "s": [-360]
          }]
        },
        "p": {
          "a": 0,
          "k": [216.84, 115.54, 0],
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "o": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "v": [
                [-2.41, -2.41],
                [2.41, -2.41],
                [2.41, 2.41],
                [-2.41, 2.41]
              ],
              "c": true
            }
          }
        }, {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1, 1, 1, 1]
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "r": 1,
          "bm": 0
        }, {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [0, 0]
          },
          "a": {
            "a": 0,
            "k": [0, 0]
          },
          "s": {
            "a": 0,
            "k": [100, 100]
          },
          "r": {
            "a": 0,
            "k": 45
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "sk": {
            "a": 0,
            "k": 0
          },
          "sa": {
            "a": 0,
            "k": 0
          }
        }],
        "bm": 0
      }],
      "ip": 0,
      "op": 750,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 14,
      "ty": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.83],
              "y": [0.83]
            },
            "o": {
              "x": [0.17],
              "y": [0.17]
            },
            "t": 0,
            "s": [0]
          }, {
            "t": 150,
            "s": [-360]
          }]
        },
        "p": {
          "a": 0,
          "k": [109.45, 87.39, 0],
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "o": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "v": [
                [-2.65, -2.65],
                [2.65, -2.65],
                [2.65, 2.65],
                [-2.65, 2.65]
              ],
              "c": true
            }
          }
        }, {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1, 1, 1, 1]
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "r": 1,
          "bm": 0
        }, {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [0, 0]
          },
          "a": {
            "a": 0,
            "k": [0, 0]
          },
          "s": {
            "a": 0,
            "k": [100, 100]
          },
          "r": {
            "a": 0,
            "k": 45
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "sk": {
            "a": 0,
            "k": 0
          },
          "sa": {
            "a": 0,
            "k": 0
          }
        }],
        "bm": 0
      }],
      "ip": 0,
      "op": 750,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 15,
      "ty": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.83],
              "y": [0.83]
            },
            "o": {
              "x": [0.17],
              "y": [0.17]
            },
            "t": 0,
            "s": [0]
          }, {
            "t": 150,
            "s": [-360]
          }]
        },
        "p": {
          "a": 0,
          "k": [87.23, 52.14, 0],
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "o": [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              "v": [
                [-1.61, -1.61],
                [1.61, -1.61],
                [1.61, 1.61],
                [-1.61, 1.61]
              ],
              "c": true
            }
          }
        }, {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1, 1, 1, 1]
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "r": 1,
          "bm": 0
        }, {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [0, 0]
          },
          "a": {
            "a": 0,
            "k": [0, 0]
          },
          "s": {
            "a": 0,
            "k": [100, 100]
          },
          "r": {
            "a": 0,
            "k": 45
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "sk": {
            "a": 0,
            "k": 0
          },
          "sa": {
            "a": 0,
            "k": 0
          }
        }],
        "bm": 0
      }],
      "ip": 0,
      "op": 750,
      "st": 0,
      "ct": 1,
      "bm": 0
    }]
  }],
  "layers": [{
    "ddd": 0,
    "ind": 1,
    "ty": 4,
    "sr": 1,
    "ks": {
      "o": {
        "a": 0,
        "k": 100
      },
      "r": {
        "a": 0,
        "k": 0
      },
      "p": {
        "s": true,
        "x": {
          "a": 0,
          "k": 127.31
        },
        "y": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.68],
              "y": [1]
            },
            "o": {
              "x": [0.39],
              "y": [0]
            },
            "t": 0,
            "s": [109.56]
          }, {
            "i": {
              "x": [0.58],
              "y": [1]
            },
            "o": {
              "x": [0.33],
              "y": [0]
            },
            "t": 39,
            "s": [118.14]
          }, {
            "i": {
              "x": [0.76],
              "y": [1]
            },
            "o": {
              "x": [0.46],
              "y": [0]
            },
            "t": 75,
            "s": [109.56]
          }, {
            "i": {
              "x": [0.66],
              "y": [1]
            },
            "o": {
              "x": [0.31],
              "y": [0]
            },
            "t": 110,
            "s": [118.14]
          }, {
            "t": 150,
            "s": [109.56]
          }]
        }
      },
      "a": {
        "a": 0,
        "k": [0, -14, 0],
        "l": 2
      },
      "s": {
        "a": 0,
        "k": [100, 100, 100],
        "l": 2
      }
    },
    "ao": 0,
    "shapes": [{
      "ty": "gr",
      "it": [{
        "ind": 0,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 16.5],
              [-16.5, 0],
              [0, -16.5],
              [16.5, 0]
            ],
            "o": [
              [0, -16.5],
              [16.5, 0],
              [0, 16.5],
              [-16.5, 0]
            ],
            "v": [
              [3.09, -68.39],
              [32.96, -98.27],
              [62.84, -68.39],
              [32.96, -38.52]
            ],
            "c": true
          }
        }
      }, {
        "ind": 1,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [17.34, 0],
              [0, -17.34],
              [-17.34, 0],
              [0, 17.34]
            ],
            "o": [
              [-17.34, 0],
              [0, 17.34],
              [17.34, 0],
              [0, -17.34]
            ],
            "v": [
              [32.96, -99.78],
              [1.57, -68.39],
              [32.96, -37],
              [64.35, -68.39]
            ],
            "c": true
          }
        }
      }, {
        "ind": 2,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [8.04, -2.87],
              [10.32, -2.16],
              [-1.2, -5.74],
              [-5.74, 1.2],
              [-11.41, 4.68],
              [0, 0.31],
              [0, 0],
              [0.21, 0.14],
              [0.23, -0.1],
              [11.12, -2.33],
              [1.03, 4.91],
              [-4.91, 1.03],
              [-8.15, 2.91],
              [-5.33, 5.25],
              [-1.51, 0.41],
              [-0.17, 0.04],
              [0.06, 0.39],
              [0, 0],
              [0.18, 0.12]
            ],
            "o": [
              [-5.11, 5.03],
              [-8.04, 2.88],
              [-5.74, 1.2],
              [1.2, 5.74],
              [11.1, -2.33],
              [0.28, -0.12],
              [0, 0],
              [0, -0.25],
              [-0.21, -0.14],
              [-11.26, 4.62],
              [-4.91, 1.03],
              [-1.03, -4.91],
              [10.32, -2.16],
              [8.15, -2.91],
              [1.87, -1.84],
              [0.17, -0.05],
              [0.38, -0.09],
              [0, 0],
              [-0.03, -0.21],
              [0, 0]
            ],
            "v": [
              [0.42, -45.26],
              [-19.44, -33.76],
              [-47.12, -26.58],
              [-55.33, -14.02],
              [-42.77, -5.81],
              [-7.91, -15.46],
              [-7.44, -16.17],
              [-7.44, -16.18],
              [-7.77, -16.81],
              [-8.48, -16.88],
              [-43.08, -7.31],
              [-53.83, -14.33],
              [-46.81, -25.09],
              [-18.92, -32.32],
              [1.49, -44.17],
              [7.1, -47.27],
              [7.62, -47.4],
              [8.2, -48.25],
              [8.19, -48.27],
              [7.87, -48.78]
            ],
            "c": false
          }
        }
      }, {
        "ind": 3,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [-0.17, -0.12]
            ],
            "o": [
              [0.21, -0.05],
              [0, 0]
            ],
            "v": [
              [7.27, -48.89],
              [7.87, -48.78]
            ],
            "c": false
          }
        }
      }, {
        "ind": 4,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [-0.19, 0.05]
            ],
            "o": [
              [0.19, -0.05],
              [0, 0]
            ],
            "v": [
              [6.7, -48.75],
              [7.27, -48.89]
            ],
            "c": false
          }
        }
      }, {
        "ind": 5,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [0, 0]
            ],
            "o": [
              [0, 0],
              [0, 0]
            ],
            "v": [
              [6.7, -48.74],
              [6.7, -48.75]
            ],
            "c": false
          }
        }
      }, {
        "ind": 6,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [0, 0]
            ],
            "o": [
              [0, 0],
              [0, 0]
            ],
            "v": [
              [6.7, -48.74],
              [6.7, -48.75]
            ],
            "c": false
          }
        }
      }, {
        "ind": 7,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [-1.65, 0.45]
            ],
            "o": [
              [2.13, -2.1],
              [0, 0]
            ],
            "v": [
              [0.42, -45.26],
              [6.7, -48.75]
            ],
            "c": false
          }
        }
      }, {
        "ind": 8,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [-0.24, -0.05],
              [-0.18, 0.16],
              [-0.62, 1.34],
              [-8.86, 6.57],
              [-6.24, 3.51],
              [-0.05, 0.21],
              [0.12, 0.18],
              [0, 0],
              [0.34, -0.19],
              [5.58, -4.13],
              [3.45, -7.44],
              [0.88, -0.79],
              [-0.13, -0.3]
            ],
            "o": [
              [0.1, 0.22],
              [0.24, 0.05],
              [1.03, -0.93],
              [3.3, -7.1],
              [5.51, -4.08],
              [0.19, -0.1],
              [0.05, -0.21],
              [0, 0],
              [-0.22, -0.32],
              [-6.27, 3.53],
              [-8.95, 6.63],
              [-0.53, 1.15],
              [-0.24, 0.22],
              [0, 0]
            ],
            "v": [
              [-58.71, 64.39],
              [-58.17, 64.83],
              [-57.51, 64.65],
              [-54.98, 61.23],
              [-36.34, 40.75],
              [-18.49, 29.41],
              [-18.12, 28.92],
              [-18.23, 28.32],
              [-18.24, 28.31],
              [-19.24, 28.07],
              [-37.25, 39.52],
              [-56.37, 60.59],
              [-58.53, 63.51],
              [-58.71, 64.38]
            ],
            "c": true
          }
        }
      }, {
        "ind": 9,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [-0.15, 0.21],
              [-7.34, 5.44],
              [-8.92, 4.74],
              [-0.05, 0.21],
              [0, 0],
              [0.24, 0.19],
              [0.27, -0.14],
              [8.74, -6.48],
              [5.45, -7.67],
              [-0.12, -0.25],
              [-0.28, -0.01]
            ],
            "o": [
              [0.26, 0.01],
              [5.32, -7.49],
              [8.65, -6.41],
              [0.19, -0.1],
              [0, 0],
              [0.07, -0.29],
              [-0.24, -0.18],
              [-8.95, 4.76],
              [-7.41, 5.5],
              [-0.16, 0.23],
              [0.12, 0.25],
              [0, 0]
            ],
            "v": [
              [-68.4, 44.55],
              [-67.75, 44.23],
              [-48.07, 24.93],
              [-20.86, 8.27],
              [-20.49, 7.79],
              [-20.48, 7.77],
              [-20.75, 6.98],
              [-21.57, 6.91],
              [-48.98, 23.7],
              [-69.01, 43.35],
              [-69.07, 44.13],
              [-68.42, 44.55]
            ],
            "c": true
          }
        }
      }, {
        "ind": 10,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [-0.24, 0.01],
              [-0.13, 0.2],
              [-0.29, 0.85],
              [-17.55, 11.57],
              [-0.4, 0.36],
              [0.24, 0.31],
              [0.33, -0.21],
              [0, 0],
              [0.03, -0.03],
              [0.37, -0.24],
              [5.92, -17.19],
              [0.4, -0.62],
              [-0.2, -0.26]
            ],
            "o": [
              [0.15, 0.19],
              [0.24, -0.01],
              [0.47, -0.72],
              [5.78, -16.77],
              [0.46, -0.3],
              [0.29, -0.26],
              [-0.24, -0.31],
              [0, 0],
              [-0.03, 0.02],
              [-0.32, 0.29],
              [-17.74, 11.69],
              [-0.25, 0.73],
              [-0.18, 0.27],
              [0, 0]
            ],
            "v": [
              [-21.21, 88.56],
              [-20.58, 88.84],
              [-19.98, 88.5],
              [-18.83, 86.13],
              [20.33, 39.86],
              [21.62, 38.86],
              [21.71, 37.84],
              [20.7, 37.66],
              [20.62, 37.71],
              [20.53, 37.78],
              [19.49, 38.58],
              [-20.27, 85.64],
              [-21.26, 87.66],
              [-21.22, 88.55]
            ],
            "c": true
          }
        }
      }, {
        "ind": 11,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [-0.1, 0.21],
              [-9.95, 9.65],
              [0.04, 0.24],
              [0.22, 0.11],
              [0, 0],
              [0.23, -0.22],
              [5.65, -12.03],
              [-0.18, -0.23],
              [-0.28, 0.07]
            ],
            "o": [
              [0.23, -0.05],
              [5.55, -11.82],
              [0.18, -0.17],
              [-0.04, -0.24],
              [0, 0],
              [-0.29, -0.14],
              [-10.06, 9.75],
              [-0.12, 0.26],
              [0.18, 0.23],
              [0, 0]
            ],
            "v": [
              [-34.82, 72.42],
              [-34.3, 72],
              [-10.15, 39.24],
              [-9.93, 38.58],
              [-10.34, 38.01],
              [-10.36, 38.01],
              [-11.22, 38.14],
              [-35.69, 71.36],
              [-35.59, 72.16],
              [-34.83, 72.42]
            ],
            "c": true
          }
        }
      }, {
        "ind": 12,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [-0.3, -0.12],
              [-3.01, -3.18],
              [-0.32, -3.25],
              [-11.24, -16.68],
              [4.86, -3.28],
              [3.28, 4.86],
              [3.75, 17.6],
              [-0.32, 0.15],
              [0, 0],
              [-0.2, -0.1],
              [-0.05, -0.22],
              [-10.01, -14.85],
              [-4.16, 2.8],
              [2.8, 4.16],
              [1.79, 18.42],
              [3.33, 3.51],
              [3.21, 1.29],
              [0.08, 0.16],
              [0, 0],
              [-0.21, 0.24]
            ],
            "o": [
              [3.21, 1.29],
              [3.68, 3.88],
              [1.75, 18.07],
              [3.28, 4.86],
              [-4.86, 3.28],
              [-10.07, -14.93],
              [-0.07, -0.35],
              [0, 0],
              [0.21, -0.09],
              [0.21, 0.1],
              [3.69, 17.33],
              [2.8, 4.16],
              [4.16, -2.8],
              [-11.32, -16.79],
              [-0.3, -3.13],
              [-2.75, -2.9],
              [-0.16, -0.07],
              [0, 0],
              [-0.15, -0.28],
              [0.21, -0.24]
            ],
            "v": [
              [47.39, -39.45],
              [57.66, -33.23],
              [62.47, -21.63],
              [82.53, 28.02],
              [79.66, 42.75],
              [64.93, 39.88],
              [43.18, -7.66],
              [43.61, -8.51],
              [43.62, -8.52],
              [44.28, -8.51],
              [44.68, -7.99],
              [66.2, 39.03],
              [78.8, 41.48],
              [81.26, 28.87],
              [60.95, -21.48],
              [56.55, -32.18],
              [46.84, -38.02],
              [46.45, -38.36],
              [46.44, -38.38],
              [46.54, -39.25]
            ],
            "c": true
          }
        }
      }, {
        "ind": 13,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [0.35, -0.23],
              [1.41, -7.92],
              [2.68, -5.99],
              [1.74, -2.28],
              [0.95, -2.8],
              [-0.32, -0.18],
              [-0.23, 0.29],
              [0, 0],
              [-0.03, 0.08],
              [-1.78, 2.34],
              [-2.74, 6.14],
              [-0.89, 5.03],
              [-5.9, 3.86],
              [-0.04, 0.2],
              [0.11, 0.17]
            ],
            "o": [
              [-0.23, -0.35],
              [-6.24, 4.08],
              [-0.87, 4.87],
              [-2.71, 6.06],
              [-1.89, 2.48],
              [-0.12, 0.35],
              [0.32, 0.18],
              [0, 0],
              [0.05, -0.07],
              [0.9, -2.63],
              [1.83, -2.39],
              [2.72, -6.07],
              [1.33, -7.49],
              [0.17, -0.11],
              [0.04, -0.2],
              [0, 0]
            ],
            "v": [
              [9.16, -49.24],
              [8.12, -49.45],
              [-4.15, -30.9],
              [-9.66, -14.02],
              [-16.91, -1.07],
              [-21.17, 6.92],
              [-20.81, 7.83],
              [-19.85, 7.63],
              [-19.83, 7.59],
              [-19.7, 7.37],
              [-15.69, -0.14],
              [-8.26, -13.39],
              [-2.64, -30.63],
              [8.95, -48.17],
              [9.28, -48.65],
              [9.17, -49.22]
            ],
            "c": true
          }
        }
      }, {
        "ind": 14,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0.39, 0.61],
              [0.22, 0.03],
              [0.17, -0.14],
              [0, 0],
              [-0.2, -0.32],
              [-0.32, -0.61],
              [-0.32, 0.06],
              [0, 0],
              [-0.11, 0.22],
              [0.11, 0.21]
            ],
            "o": [
              [-0.12, -0.18],
              [-0.21, -0.03],
              [0, 0],
              [-0.29, 0.24],
              [0.37, 0.58],
              [0.15, 0.29],
              [0, 0],
              [0.24, -0.04],
              [0.11, -0.22],
              [-0.34, -0.64]
            ],
            "v": [
              [47.15, -40.94],
              [46.63, -41.29],
              [46.03, -41.12],
              [46.01, -41.11],
              [45.86, -40.13],
              [46.89, -38.35],
              [47.69, -37.96],
              [47.71, -37.96],
              [48.25, -38.37],
              [48.24, -39.06]
            ],
            "c": true
          }
        }
      }, {
        "ind": 15,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [0.3, -0.02],
              [0.11, -0.28],
              [5.96, -7.81],
              [2.04, -1.48],
              [-0.21, -0.34],
              [0, 0],
              [-0.21, -0.04],
              [-0.17, 0.12],
              [-1.7, 2.23],
              [-4.82, 12.87],
              [0.1, 0.19]
            ],
            "o": [
              [-0.14, -0.27],
              [-0.3, 0.02],
              [-4.77, 12.76],
              [-1.61, 2.11],
              [-0.32, 0.24],
              [0, 0],
              [0.11, 0.18],
              [0.21, 0.04],
              [2.16, -1.57],
              [6.06, -7.95],
              [0.07, -0.2],
              [0, 0]
            ],
            "v": [
              [45.04, -0.46],
              [44.32, -0.87],
              [43.65, -0.38],
              [26.45, 31.99],
              [20.94, 37.38],
              [20.74, 38.4],
              [20.75, 38.41],
              [21.25, 38.75],
              [21.84, 38.62],
              [27.67, 32.92],
              [45.08, 0.17],
              [45.04, -0.44]
            ],
            "c": true
          }
        }
      }, {
        "ind": 16,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [0.14, -0.19],
              [-0.07, -0.23],
              [-4.74, -3.61],
              [-0.31, -0.22],
              [-0.26, 0.27],
              [0, 0],
              [0.02, 0.22],
              [0.18, 0.13],
              [0.29, 0.22],
              [1.6, 4.98],
              [0.33, 0]
            ],
            "o": [
              [-0.24, 0],
              [-0.14, 0.2],
              [1.69, 5.27],
              [0.3, 0.23],
              [0.31, 0.22],
              [0, 0],
              [0.15, -0.16],
              [-0.02, -0.22],
              [-0.29, -0.2],
              [-4.48, -3.41],
              [-0.1, -0.31],
              [0, 0]
            ],
            "v": [
              [-20.58, 23.51],
              [-21.2, 23.82],
              [-21.31, 24.5],
              [-11.61, 38.21],
              [-10.7, 38.88],
              [-9.71, 38.79],
              [-9.7, 38.78],
              [-9.5, 38.18],
              [-9.82, 37.63],
              [-10.68, 37],
              [-19.85, 24.04],
              [-20.57, 23.51]
            ],
            "c": true
          }
        }
      }, {
        "ind": 17,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [0.38, 0.18],
              [0.18, -0.38],
              [0, 0],
              [4.74, 1.74],
              [0, 0],
              [0.14, -0.39],
              [-0.39, -0.14],
              [0, 0],
              [-2.56, 5.23]
            ],
            "o": [
              [0.18, -0.38],
              [-0.38, -0.18],
              [0, 0],
              [-2.22, 4.53],
              [0, 0],
              [-0.39, -0.14],
              [-0.14, 0.39],
              [0, 0],
              [5.46, 2.01],
              [0, 0]
            ],
            "v": [
              [53.04, 19.29],
              [52.69, 18.28],
              [51.67, 18.63],
              [46.08, 30.06],
              [33.84, 34.97],
              [27.56, 32.67],
              [26.59, 33.12],
              [27.04, 34.09],
              [33.32, 36.4],
              [47.44, 30.73]
            ],
            "c": true
          }
        }
      }, {
        "ind": 18,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [0.14, -0.39],
              [-0.39, -0.14],
              [0, 0],
              [1.52, -5.56],
              [2.38, -3.71],
              [-0.35, -0.23],
              [-0.23, 0.35],
              [-5.46, 19.98],
              [6.18, 2.19]
            ],
            "o": [
              [-0.39, -0.14],
              [-0.14, 0.39],
              [0, 0],
              [5.47, 1.93],
              [-5.44, 19.91],
              [-0.23, 0.35],
              [0.35, 0.23],
              [2.5, -3.9],
              [1.74, -6.36],
              [0, 0]
            ],
            "v": [
              [64.09, -63.22],
              [63.12, -62.76],
              [63.58, -61.8],
              [69.58, -59.67],
              [76.67, -46.44],
              [63.2, -10.99],
              [63.42, -9.94],
              [64.47, -10.17],
              [78.14, -46.04],
              [70.09, -61.1]
            ],
            "c": true
          }
        }
      }, {
        "ind": 19,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [4.51, 6.66],
              [17.38, -3.97],
              [-0.09, -0.41],
              [-0.41, 0.09],
              [-6.37, -9.41],
              [0.77, -6.64],
              [3.83, -1.84],
              [7.99, 3.19],
              [2.59, 2.17],
              [0.63, 1.65],
              [0.39, -0.15],
              [-0.15, -0.39],
              [-2.69, -2.26],
              [-4.15, -1.66],
              [-10.95, 5.26],
              [-0.55, 4.73]
            ],
            "o": [
              [-6.75, -9.96],
              [-0.41, 0.09],
              [0.09, 0.41],
              [16.82, -3.85],
              [4.24, 6.26],
              [-0.49, 4.22],
              [-10.49, 5.04],
              [-4, -1.6],
              [-2.6, -2.19],
              [-0.15, -0.39],
              [-0.39, 0.15],
              [0.77, 1.99],
              [2.71, 2.28],
              [8.3, 3.31],
              [4.3, -2.06],
              [0.79, -6.85]
            ],
            "v": [
              [52.01, -80.09],
              [13.36, -92.91],
              [12.79, -92.01],
              [13.7, -91.44],
              [50.76, -79.24],
              [54.98, -58.77],
              [48.05, -48.99],
              [19.31, -48.18],
              [9.31, -54.08],
              [4.38, -60.09],
              [3.4, -60.52],
              [2.96, -59.54],
              [8.33, -52.92],
              [18.75, -46.77],
              [48.71, -47.63],
              [56.48, -58.6]
            ],
            "c": true
          }
        }
      }, {
        "ind": 20,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [2.06, 0.28],
              [0.06, -0.41],
              [-0.41, -0.06],
              [-2.32, -1.99],
              [-0.08, -4.48],
              [-0.42, 0.01],
              [0.01, 0.42],
              [2.6, 2.23]
            ],
            "o": [
              [-0.41, -0.06],
              [-0.06, 0.41],
              [1.81, 0.25],
              [2.3, 1.97],
              [0.01, 0.42],
              [0.42, -0.01],
              [-0.08, -4.95],
              [-2.57, -2.21]
            ],
            "v": [
              [-53.5, 28.72],
              [-54.35, 29.37],
              [-53.7, 30.22],
              [-46.81, 33.4],
              [-42.56, 42.87],
              [-41.79, 43.62],
              [-41.05, 42.85],
              [-45.82, 32.25]
            ],
            "c": true
          }
        }
      }, {
        "ind": 21,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [2.05, -0.32],
              [-0.06, -0.41],
              [-0.41, 0.06],
              [-2.79, -1.25],
              [-1.36, -4.27],
              [-0.4, 0.13],
              [0.13, 0.4],
              [3.13, 1.39]
            ],
            "o": [
              [-0.41, 0.06],
              [0.06, 0.41],
              [1.81, -0.28],
              [2.77, 1.23],
              [0.13, 0.4],
              [0.4, -0.13],
              [-1.5, -4.72],
              [-3.1, -1.38]
            ],
            "v": [
              [-23.46, 52.88],
              [-24.09, 53.74],
              [-23.22, 54.38],
              [-15.71, 55.45],
              [-8.93, 63.31],
              [-7.98, 63.8],
              [-7.48, 62.85],
              [-15.09, 54.07]
            ],
            "c": true
          }
        }
      }, {
        "ind": 22,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [-1.23, 1.14],
              [-2.53, -1.36],
              [-2.88, -3.11],
              [-1.17, -2.56],
              [0.04, -0.94],
              [0.71, -0.65],
              [0.92, 0.03],
              [1.23, 0.68],
              [2.89, 3.12],
              [1.15, 2.59]
            ],
            "o": [
              [1.23, -1.14],
              [2.5, 1.34],
              [2.89, 3.12],
              [0.59, 1.28],
              [-0.04, 0.92],
              [-0.7, 0.65],
              [-0.94, -0.03],
              [-2.47, -1.36],
              [-2.88, -3.11],
              [-1.16, -2.63]
            ],
            "v": [
              [-81.84, 46.8],
              [-76.22, 47.06],
              [-67.98, 53.98],
              [-61.61, 62.66],
              [-60.79, 66],
              [-61.86, 68.35],
              [-64.29, 69.25],
              [-67.56, 68.17],
              [-75.73, 61.16],
              [-82.01, 52.42]
            ],
            "c": true
          }
        }
      }, {
        "ind": 23,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [2.91, 3.14],
              [2.72, 1.46],
              [1.98, -1.83],
              [-1.23, -2.78],
              [-2.92, -3.15],
              [-2.69, -1.48],
              [-1.27, -0.04],
              [-1.01, 0.94],
              [-0.05, 1.3],
              [0.64, 1.4]
            ],
            "o": [
              [-2.92, -3.15],
              [-2.68, -1.44],
              [-1.98, 1.84],
              [1.25, 2.82],
              [2.91, 3.14],
              [1.35, 0.74],
              [1.3, 0.05],
              [1.01, -0.94],
              [0.05, -1.27],
              [-1.28, -2.79]
            ],
            "v": [
              [-66.87, 52.96],
              [-75.51, 45.72],
              [-82.87, 45.69],
              [-83.39, 53.04],
              [-76.84, 62.19],
              [-68.29, 69.5],
              [-64.34, 70.76],
              [-60.83, 69.46],
              [-59.27, 66.06],
              [-60.23, 62.03]
            ],
            "c": true
          }
        }
      }, {
        "ind": 24,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [-0.35, 0.23],
              [-3.41, -0.06],
              [-3.39, -4.21],
              [2.68, -2.64],
              [-0.29, -0.3],
              [-0.3, 0.29],
              [7.31, 9.09],
              [3.83, 0.07],
              [2.14, -1.39],
              [-0.23, -0.35]
            ],
            "o": [
              [1.96, -1.27],
              [3.38, 0.06],
              [6.8, 8.46],
              [-0.3, 0.29],
              [0.29, 0.3],
              [3.2, -3.16],
              [-3.67, -4.56],
              [-3.79, -0.07],
              [-0.35, 0.23],
              [0.23, 0.35]
            ],
            "v": [
              [-81.68, 46.6],
              [-73.23, 44.1],
              [-62.69, 49.77],
              [-61.53, 68.08],
              [-61.54, 69.15],
              [-60.47, 69.16],
              [-61.51, 48.82],
              [-73.2, 42.59],
              [-82.51, 45.33],
              [-82.73, 46.38]
            ],
            "c": true
          }
        }
      }, {
        "ind": 25,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [-1.23, 1.14],
              [-2.53, -1.36],
              [-2.88, -3.11],
              [-1.17, -2.56],
              [0.04, -0.94],
              [0.71, -0.65],
              [0.92, 0.03],
              [1.23, 0.68],
              [2.89, 3.12],
              [1.15, 2.59]
            ],
            "o": [
              [1.23, -1.14],
              [2.5, 1.34],
              [2.89, 3.12],
              [0.59, 1.28],
              [-0.04, 0.92],
              [-0.7, 0.65],
              [-0.94, -0.03],
              [-2.47, -1.36],
              [-2.88, -3.11],
              [-1.16, -2.63]
            ],
            "v": [
              [-45.08, 75.82],
              [-39.46, 76.08],
              [-31.22, 83],
              [-24.84, 91.67],
              [-24.02, 95.02],
              [-25.1, 97.37],
              [-27.52, 98.26],
              [-30.8, 97.19],
              [-38.96, 90.18],
              [-45.24, 81.44]
            ],
            "c": true
          }
        }
      }, {
        "ind": 26,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [2.91, 3.14],
              [2.72, 1.46],
              [1.98, -1.83],
              [-1.23, -2.78],
              [-2.92, -3.15],
              [-2.69, -1.48],
              [-1.27, -0.04],
              [-1.01, 0.94],
              [-0.05, 1.3],
              [0.64, 1.4]
            ],
            "o": [
              [-2.92, -3.15],
              [-2.68, -1.44],
              [-1.98, 1.84],
              [1.25, 2.82],
              [2.91, 3.14],
              [1.35, 0.74],
              [1.3, 0.05],
              [1.01, -0.94],
              [0.05, -1.27],
              [-1.28, -2.79]
            ],
            "v": [
              [-30.1, 81.97],
              [-38.74, 74.74],
              [-46.11, 74.7],
              [-46.63, 82.05],
              [-40.07, 91.21],
              [-31.53, 98.52],
              [-27.58, 99.78],
              [-24.07, 98.48],
              [-22.51, 95.08],
              [-23.47, 91.04]
            ],
            "c": true
          }
        }
      }, {
        "ind": 27,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [-0.35, 0.23],
              [-3.41, -0.06],
              [-3.39, -4.21],
              [2.68, -2.64],
              [-0.29, -0.3],
              [-0.3, 0.29],
              [7.31, 9.09],
              [3.83, 0.07],
              [2.14, -1.39],
              [-0.23, -0.35]
            ],
            "o": [
              [1.96, -1.27],
              [3.38, 0.06],
              [6.8, 8.46],
              [-0.3, 0.29],
              [0.29, 0.3],
              [3.2, -3.16],
              [-3.67, -4.56],
              [-3.79, -0.07],
              [-0.35, 0.23],
              [0.23, 0.35]
            ],
            "v": [
              [-44.92, 75.62],
              [-36.46, 73.12],
              [-25.93, 78.79],
              [-24.77, 97.1],
              [-24.77, 98.17],
              [-23.7, 98.17],
              [-24.75, 77.84],
              [-36.43, 71.6],
              [-45.75, 74.35],
              [-45.97, 75.4]
            ],
            "c": true
          }
        }
      }, {
        "ind": 28,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [0.83, -1.81],
              [0, 0],
              [-1.95, -0.84],
              [0, 0],
              [-0.83, 1.91],
              [0, 0],
              [2.03, 0.76]
            ],
            "o": [
              [-1.86, -0.7],
              [0, 0],
              [-0.88, 1.93],
              [0, 0],
              [1.92, 0.82],
              [0, 0],
              [0.86, -1.99],
              [0, 0]
            ],
            "v": [
              [12.17, -27.19],
              [7.39, -25.22],
              [3.44, -16.57],
              [5.4, -11.51],
              [13.57, -8.01],
              [18.54, -9.98],
              [22.48, -19.08],
              [20.34, -24.13]
            ],
            "c": true
          }
        }
      }, {
        "ind": 29,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [-1.12, -0.42],
              [0, 0],
              [0.52, -1.19],
              [0, 0],
              [1.15, 0.49],
              [0, 0],
              [-0.53, 1.16]
            ],
            "o": [
              [0.5, -1.09],
              [0, 0],
              [1.22, 0.46],
              [0, 0],
              [-0.5, 1.15],
              [0, 0],
              [-1.17, -0.5],
              [0, 0]
            ],
            "v": [
              [8.77, -24.59],
              [11.63, -25.77],
              [19.8, -22.71],
              [21.09, -19.68],
              [17.15, -10.59],
              [14.17, -9.4],
              [5.99, -12.9],
              [4.82, -15.94]
            ],
            "c": true
          }
        }
      }, {
        "ind": 30,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [-1.79, 0],
              [0, 1.79],
              [1.79, 0],
              [0, -1.79]
            ],
            "o": [
              [1.79, 0],
              [0, -1.79],
              [-1.79, 0],
              [0, 1.79]
            ],
            "v": [
              [-0.39, 0.19],
              [2.85, -3.04],
              [-0.39, -6.27],
              [-3.62, -3.04]
            ],
            "c": true
          }
        }
      }, {
        "ind": 31,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [-1.79, 0],
              [0, 1.79],
              [1.79, 0],
              [0, -1.79]
            ],
            "o": [
              [1.79, 0],
              [0, -1.79],
              [-1.79, 0],
              [0, 1.79]
            ],
            "v": [
              [9.35, 4.32],
              [12.58, 1.09],
              [9.35, -2.15],
              [6.12, 1.09]
            ],
            "c": true
          }
        }
      }, {
        "ind": 32,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [3.74, 6.11],
              [0.36, -0.22],
              [-0.22, -0.36],
              [-26.23, -7.65],
              [-0.12, 0.4],
              [0.4, 0.12]
            ],
            "o": [
              [-0.22, -0.36],
              [-0.36, 0.22],
              [3.96, 6.47],
              [0.4, 0.12],
              [0.12, -0.4],
              [-25.91, -7.56]
            ],
            "v": [
              [-13.86, -0.95],
              [-14.9, -1.2],
              [-15.15, -0.16],
              [29.41, 28.55],
              [30.35, 28.04],
              [29.83, 27.1]
            ],
            "c": true
          }
        }
      }, {
        "ind": 33,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0]
            ],
            "o": [
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0]
            ],
            "v": [
              [0.42, -45.26],
              [7.87, -48.78],
              [7.27, -48.89],
              [6.7, -48.75]
            ],
            "c": true
          }
        }
      }, {
        "ty": "mm",
        "mm": 1
      }, {
        "ty": "fl",
        "c": {
          "a": 0,
          "k": [1, 1, 1, 1]
        },
        "o": {
          "a": 0,
          "k": 100
        },
        "r": 1,
        "bm": 0
      }, {
        "ty": "tr",
        "p": {
          "a": 0,
          "k": [0, 0]
        },
        "a": {
          "a": 0,
          "k": [0, 0]
        },
        "s": {
          "a": 0,
          "k": [100, 100]
        },
        "r": {
          "a": 0,
          "k": 0
        },
        "o": {
          "a": 0,
          "k": 100
        },
        "sk": {
          "a": 0,
          "k": 0
        },
        "sa": {
          "a": 0,
          "k": 0
        }
      }],
      "bm": 0
    }],
    "ip": 0,
    "op": 750,
    "st": 0,
    "ct": 1,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 2,
    "ty": 3,
    "sr": 1,
    "ks": {
      "o": {
        "a": 0,
        "k": 0
      },
      "r": {
        "a": 1,
        "k": [{
          "i": {
            "x": [0.83],
            "y": [0.83]
          },
          "o": {
            "x": [0.17],
            "y": [0.17]
          },
          "t": 0,
          "s": [0]
        }, {
          "t": 150,
          "s": [360]
        }]
      },
      "p": {
        "a": 0,
        "k": [128, 128, 0],
        "l": 2
      },
      "a": {
        "a": 0,
        "k": [0, 0, 0],
        "l": 2
      },
      "s": {
        "a": 0,
        "k": [100, 100, 100],
        "l": 2
      }
    },
    "ao": 0,
    "ip": 0,
    "op": 750,
    "st": 0,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 3,
    "ty": 4,
    "parent": 1,
    "sr": 1,
    "ks": {
      "o": {
        "a": 0,
        "k": 100
      },
      "r": {
        "a": 0,
        "k": 0
      },
      "p": {
        "a": 0,
        "k": [-0.27, -0.19, 0],
        "l": 2
      },
      "a": {
        "a": 0,
        "k": [0, 0, 0],
        "l": 2
      },
      "s": {
        "a": 0,
        "k": [100, 100, 100],
        "l": 2
      }
    },
    "ao": 0,
    "shapes": [{
      "ty": "gr",
      "it": [{
        "ind": 0,
        "ty": "sh",
        "ks": {
          "a": 0,
          "k": {
            "i": [
              [7.44, -2.69],
              [-1.2, -2.86],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [8.4, 10],
              [4.5, -0.33],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0]
            ],
            "o": [
              [-7.44, 2.69],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [1.33, -4.17],
              [-8.4, -10],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
              [-2.97, 0.35]
            ],
            "v": [
              [-48.1, -24.83],
              [-53.79, -12.88],
              [-51.38, -8.63],
              [-46.57, -6.39],
              [-42.16, -6.39],
              [-25.89, -10],
              [-7.61, -16.01],
              [-12.98, -4.95],
              [-17.95, 2.35],
              [-20.12, 7.32],
              [-26.53, 10.77],
              [-39.44, 18.7],
              [-52.03, 27.76],
              [-63.09, 37.55],
              [-68.22, 44.28],
              [-73.75, 43.64],
              [-79.12, 44.92],
              [-82.25, 46.77],
              [-83.45, 49.33],
              [-82.25, 53.42],
              [-79.12, 58.07],
              [-73.75, 64.4],
              [-68.94, 68.33],
              [-64.77, 69.86],
              [-62.37, 69.86],
              [-59.96, 67.53],
              [-57.24, 63.68],
              [-53.79, 57.59],
              [-44.65, 46.77],
              [-32.78, 37.55],
              [-18.43, 29.05],
              [-15.07, 33.86],
              [-10.1, 38.27],
              [-21.48, 51.01],
              [-30.14, 62.96],
              [-34.79, 72.74],
              [-40.32, 73.22],
              [-44.65, 74.51],
              [-46.65, 77.39],
              [-45.93, 81.48],
              [-41.36, 88.86],
              [-34.79, 95.27],
              [-29.74, 98.96],
              [-26.45, 98.96],
              [-23.48, 96.79],
              [-21.48, 94.23],
              [-20.84, 89.34],
              [-16.43, 79.4],
              [-8.49, 66.33],
              [2.09, 54.06],
              [12.27, 44.92],
              [21.73, 38.27],
              [26.94, 32.9],
              [31.11, 35.14],
              [38.81, 36.66],
              [42.98, 34.66],
              [46.59, 31.08],
              [53.08, 18.38],
              [61.74, 33.86],
              [67.51, 41.79],
              [72.08, 43.58],
              [77.45, 43.58],
              [81.45, 40.58],
              [83.45, 36.08],
              [83.45, 31.08],
              [80.45, 25.58],
              [72.95, 13.58],
              [67.95, 3.08],
              [63.45, -10.42],
              [65.45, -13.42],
              [72.95, -30.42],
              [77.95, -45.42],
              [77.95, -51.92],
              [75.45, -56.92],
              [70.95, -59.92],
              [63.45, -62.42],
              [56.95, -87.42],
              [32.95, -98.92],
              [22.45, -97.42],
              [16.95, -93.92],
              [9.45, -87.42],
              [4.34, -78.55],
              [2.49, -66.92],
              [4.34, -57.3],
              [8.99, -48.88],
              [5.06, -47.52],
              [-3.04, -40.54],
              [-16.03, -33.97],
              [-29.9, -29.24]
            ],
            "c": true
          }
        }
      }, {
        "ty": "fl",
        "c": {
          "a": 0,
          "k": [0, 0, 0, 1]
        },
        "o": {
          "a": 0,
          "k": 100
        },
        "r": 1,
        "bm": 0
      }, {
        "ty": "tr",
        "p": {
          "a": 0,
          "k": [0, 0]
        },
        "a": {
          "a": 0,
          "k": [0, 0]
        },
        "s": {
          "a": 0,
          "k": [100, 100]
        },
        "r": {
          "a": 0,
          "k": 0
        },
        "o": {
          "a": 0,
          "k": 100
        },
        "sk": {
          "a": 0,
          "k": 0
        },
        "sa": {
          "a": 0,
          "k": 0
        }
      }],
      "bm": 0
    }],
    "ip": 0,
    "op": 750,
    "st": 0,
    "ct": 1,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 4,
    "ty": 0,
    "parent": 2,
    "refId": "comp_0",
    "sr": 1,
    "ks": {
      "o": {
        "a": 0,
        "k": 100
      },
      "r": {
        "a": 0,
        "k": 0
      },
      "p": {
        "a": 0,
        "k": [0, 0, 0],
        "l": 2
      },
      "a": {
        "a": 0,
        "k": [128, 128, 0],
        "l": 2
      },
      "s": {
        "a": 0,
        "k": [100, 100, 100],
        "l": 2
      }
    },
    "ao": 0,
    "w": 256,
    "h": 256,
    "ip": 0,
    "op": 750,
    "st": 0,
    "bm": 0
  }],
  "markers": [],
  "props": {}
};

@Component({
  selector: 'app-loader-astronaut',
  standalone: true,
  imports: [],
  templateUrl: './loader-astronaut.component.html',
  styleUrl: './loader-astronaut.component.scss'
})
export class LoaderAstronautComponent {
  @ViewChild('lottieContainer', { static: true }) lottieContainer!: ElementRef;

  ngOnInit() {
    lottie.default.loadAnimation({
      container: this.lottieContainer.nativeElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: AnimationData
    });
  }
}
