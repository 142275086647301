import { APP_INITIALIZER, Provider } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, map, Observable } from "rxjs";
import { UserControllerService } from "./partials/services/user-controller.service";

export const appConfigInit: Provider = {
  provide: APP_INITIALIZER,
  useFactory: (router: Router, userControllerService: UserControllerService) =>
    (): Observable<boolean | Promise<boolean>> => {
      const url: URL = new URL(window.location.href);
      const queryParamsReferralLink: string | null = url.searchParams.get('referralLink');
      const queryParamsHash: string | null = url.searchParams.get('hash');
      const form = {
        referralCode: queryParamsReferralLink,
        reportedHash: queryParamsHash
      }

      return userControllerService.getUser(form).pipe(
        map(async (user) => {
          if (!user) {
            return await router.navigate(['401']);
          }
          return true;
        }),
        catchError(() => {
          return router.navigate(['401']);
        }))
    }
  ,
  deps: [
    Router,
    UserControllerService
  ],
  multi: true
};
