import { AfterViewInit, Component, inject } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { svgIcons } from './icons';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { LoaderRouterEventService } from './partials/services/loader-router-event.service';
import { LoaderComponent } from './partials/components/loader/loader.component';
import { LoaderInitialService } from './partials/services/loader-initial.service';
import { LoaderAstronautComponent } from './partials/components/loader-astronaut/loader-astronaut.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AsyncPipe, LoaderComponent, LoaderAstronautComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements AfterViewInit {
  private _iconRegistry = inject(MatIconRegistry);
  private _sanitizer = inject(DomSanitizer);
  private _router = inject(Router);
  public loaderRouterEventService = inject(LoaderRouterEventService);
  public loaderInitialService = inject(LoaderInitialService);

  constructor() {
    this._registerIcons(svgIcons);

    this._router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationStart) {
        this.loaderRouterEventService.start();
      } else if (routerEvent instanceof NavigationEnd) {
        this.loaderRouterEventService.stop();
      } else if (routerEvent instanceof NavigationCancel) {
        this.loaderRouterEventService.stop();
      } else if (routerEvent instanceof NavigationError) {
        this.loaderRouterEventService.stop();
      }
    });
  }

  ngAfterViewInit(): void {
    this._expandWindowTelegram();
  }

  private _registerIcons(icons: Array<string>): void {
    icons.forEach((icon: string) => {
      this._iconRegistry.addSvgIcon(
        icon,
        this._sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${ icon }.svg`)
      );
    });
  }

  private _expandWindowTelegram(): void {
    window.Telegram.WebApp.expand();
  }
}
