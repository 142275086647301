import { SafeCryptoService } from "./api/services/safe-crypto.service";
import { inject } from "@angular/core";
import { UserControllerService } from "./partials/services/user-controller.service";
import { catchError, of } from "rxjs";
import { ActivatedRouteSnapshot, Router } from "@angular/router";

export const resolverGetIncidents = () => {
  const { user }: UserControllerService = inject(UserControllerService);
  const safeCryptoService: SafeCryptoService = inject(SafeCryptoService);
  return safeCryptoService.getIncidents(user().id).pipe(catchError(() => of([])));
}

export const resolverGetIncidentWithId = (activatedRouteSnapshot: ActivatedRouteSnapshot) => {
  const route: Router = inject(Router);
  const { queryParams } = activatedRouteSnapshot;
  const caseId: string = queryParams['caseId'];
  const safeCryptoService: SafeCryptoService = inject(SafeCryptoService);
  return safeCryptoService.getIncidentWithId(caseId).pipe(catchError(handlerResolverGetIncidentWithIdError(route)));
}

export const resolverGetIncident = () => {
  const safeCryptoService: SafeCryptoService = inject(SafeCryptoService);
  return safeCryptoService.getIncident();
}

const handlerResolverGetIncidentWithIdError = (route: Router) => async (error: any) => {
  await route.navigate(['/history']);
  throw error;
};
