import { APP_INITIALIZER, Provider } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, map, Observable } from "rxjs";
import { UserControllerService } from "./partials/services/user-controller.service";
import { TranslateService } from "@ngx-translate/core";

export const appConfigInit: Provider = {
  provide: APP_INITIALIZER,
  useFactory: (router: Router, userControllerService: UserControllerService, translateService: TranslateService) =>
    (): Observable<boolean | Promise<boolean>> => {
      const url: URL = new URL(window.location.href);
      const queryParamsReferralLink: string | null = url.searchParams.get('referralLink');
      const queryParamsHash: string | null = url.searchParams.get('hash');
      const paramsUser: {userId: string | null, fullName: string | null, username: string | null, language: string } = {
        userId: (url.searchParams.get('userId') ? url.searchParams.get('userId') : ''),
        fullName: (url.searchParams.get('fullName') ? url.searchParams.get('fullName') : ''),
        username: (url.searchParams.get('username') ? url.searchParams.get('username') : ''),
        language: url.searchParams.get('language') ?? '',
      }
      // alert(JSON.stringify(paramsUser.language));
      const form = {
        referralCode: queryParamsReferralLink,
        reportedHash: queryParamsHash
      }
      translateService.use('en');

      return userControllerService.getUser(form, paramsUser).pipe(
        map(async (user) => {
          if (!user) {
            return await router.navigate(['401']);
          }

          const supportLocales = ['en', 'ru'];
          const { language } = user;
          translateService.addLangs(supportLocales);
          translateService.use(supportLocales.includes(language.toLowerCase()) ? language.toLowerCase() : 'en');
          return true;
        }),
        catchError(() => {
          return router.navigate(['401']);
        }))
    }
  ,
  deps: [
    Router,
    UserControllerService,
    TranslateService
  ],
  multi: true
};
