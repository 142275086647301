import { CanActivateFn, NavigationExtras, Router } from '@angular/router';
import { inject } from "@angular/core";
import { UserControllerService } from "../services/user-controller.service";

export const existIncidentIdGuard = (commands: any[], navigationExtras?: NavigationExtras): CanActivateFn =>
  (route, state) => {
    const userControllerService: UserControllerService = inject(UserControllerService);
    const router = inject(Router);
    const { user } = userControllerService;
    const userEntity = user();
    if (userEntity) {
      const { actualIncidentId } = userEntity;
      return actualIncidentId ? router.createUrlTree(commands, navigationExtras) : true;
    } else {
      return true;
    }
  };
