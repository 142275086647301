export const svgIcons: string[] = [
  'upload',
  'file-pdf',
  'file-close',
  'around-plus',
  'btn-arrow-white',
  'lang-arrow',
  'share',
  'eye',
  'watch-video',
  'round-info',
  'round-back',
  'copy-icon',
  'arrow-down-long',
  'icon-list',
  'folder-border',
  'folder-red',
  'info',
  'group',
  'lawyer',
  'victim',
  'organizacion',
  'person',
  'flag-ua',
  'search',
  'choose-cripto',
  'round-close',
  'none',
  'union',
  'pdf-file',
  'round-error',
  'round-success',
  'close',
  'around-question',
  'attention',
  'arrow-right',
  'btn-back',
  'red-warning',
  'scammer-icon',
  'icon-usdt',
  'around-receipt',
  'recovery-extremely_low',
  'recovery-low',
  'recovery-medium',
  'recovery-high',
  'recovery-extremely_high',
  'icon-status-completed',
  'icon-status-done',
  'icon-invoice'
];
