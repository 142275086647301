import { Routes } from '@angular/router';
import { existIncidentIdGuard } from "./partials/guards/exist-incident-id.guard";
import { doesNotExistIncidentIdGuard } from "./partials/guards/does-not-exist-incident-id.guard";
import { authGuard } from "./partials/guards/auth.guard";
import { resolverGetIncident, resolverGetIncidents, resolverGetIncidentWithId } from "./resolvers";
import { doesNotAgreeTermsGuard } from './partials/guards/agree-terms.guard';
import { checkQueryParamsGuard } from './partials/guards/check-query-params.guard';
import { caseGuard } from "./partials/guards/case.guard";

export const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    loadComponent: () => import('./layouts/main-layout/main-layout.component').then(({ MainLayoutComponent }) => MainLayoutComponent),
    children: [
      {
        path: '',
        canActivate: [existIncidentIdGuard(['/case'])],
        loadComponent: () => import('./pages/home/home.component').then(({ HomeComponent }) => HomeComponent)
      },
      {
        path: 'case',
        canActivate: [caseGuard],
        resolve: {
          incident: resolverGetIncident
        },
        loadComponent: () => import('./pages/case/case.component').then(({ CaseComponent }) => CaseComponent)
      },
      {
        path: 'history',
        resolve: {
          initialIncidents: resolverGetIncidents
        },
        canActivate: [doesNotAgreeTermsGuard(['/'])],
        loadComponent: () => import('./pages/history/history.component').then(({ HistoryComponent }) => HistoryComponent)
      },
      {
        path: 'crypto-recovery',
        loadComponent: () => import('./pages/crypto-recovery/crypto-recovery.component').then(({ CryptoRecoveryComponent }) => CryptoRecoveryComponent)
      },
      {
        path: 'terms-of-use',
        loadComponent: () => import('./pages/terms-of-use/terms-of-use.component').then(({ TermsOfUseComponent }) => TermsOfUseComponent)
      },
      {
        path: 'applied-case',
        canActivate: [doesNotAgreeTermsGuard(['/']), checkQueryParamsGuard(['caseId'])],
        resolve: {
          incidentInput: resolverGetIncidentWithId
        },
        loadComponent: () => import('./pages/applied-case/applied-case.component').then(({AppliedCaseComponent}) => AppliedCaseComponent)
      },
    ],
  },
  {
    path: '401',
    loadComponent: () => import('./pages/401/401.component')
  },
  {
    path: '409',
    loadComponent: () => import('./pages/something-went-wrong/something-went-wrong.component')
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'history'
  }
];
