import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderRouterEventService {
  private readonly _isLoading: WritableSignal<boolean> = signal<boolean>(false);
  readonly isLoading: Signal<boolean> = computed(() => this._isLoading());

  public start(): void {
    this._isLoading.set(true);
  }

  public stop(): void {
    this._isLoading.set(false);
  }
}
