import { HttpInterceptorFn } from '@angular/common/http';

export const tokenInterceptor: HttpInterceptorFn = (request, next) => {
  const token = localStorage.getItem('token');
  const login = request.url.includes('v1/users/login');
  if (token && !login) {
    request = request.clone({
      setHeaders: { 'x-auth-session': token },
    });
  } else {
    localStorage.getItem('token') && localStorage.removeItem('token');
  }

  return next(request);
};
