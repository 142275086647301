import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

export const checkQueryParamsGuard = (keys: string[]): CanActivateFn => (route, state): UrlTree | boolean => {
  const router: Router = inject(Router);
  const matSnackBar: MatSnackBar = inject(MatSnackBar);

  for (const key of keys) {
    if (!route.queryParams[key]) {
      matSnackBar.open(`Missing parameter: ${ key }; You have been redirected to the start page`, 'Close');
      return router.createUrlTree(['/']);
    }
  }

  return true;
};

